import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Grid from 'src/components/Grid'
import MoreFromThePost from 'src/components/MoreFromThePost'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { colors, typography, animations, mq, util } from 'src/styles'
import Drawer from 'src/components/Drawer'
import MobileMenu from 'src/components/MobileMenu'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const Text = styled.div`
  text-align: center;
	p {
		${ ({ textSize }) => typography[textSize] }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const H1 = styled.div`
  ${ typography.h1Alt }
  text-align: center;
  padding-bottom: 1em;
`

class Lookbook extends React.Component {
  render () {
    const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.internalName.includes('PLACEHOLDER'))[0].node
    const lookbook = this.props.data.allContentfulLookbook.edges[0].node
    const { sections } = lookbook
    const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
    const seo = lookbook.seo

    return (
      <Fragment >
        <SEO
          title={lookbook.displayTitle}
          description={seo.description && seo.description.description}
          siteSettings={site}
          keywords={seo.keywords}
          shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
        />
        <Header
          bannerText={site && site.bannerText}
          bannerColor={site && site.bannerColor}
          navigation={site && site.navigation}
        />

        <Drawer />
        <ResponsiveComponent
          small={
            <MobileMenu
              navLinks={site && site.navigation}
            />
          }
          large={<span />}
        />

        {/*<Section
          isFirstSection
        >
          <Grid
            small="1 [12] 1"
            medium="2 [12] 2"
            large="3 [8] 3"
            larger="4 [6] 4"
          >
            <H1>{lookbook.displayTitle}</H1>
          </Grid>
        </Section>*/}
        <div>
          {sections && sections.map((section, index) => {
            const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
            const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
            const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
            const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
            const lastSection = sections.length === index + 1
            return (
              <ComponentRenderer
                prevTheme={prevFullWidth ? false : prevTheme}
                nextTheme={nextFullWidth ? false : nextTheme}
                // isFirstSection={index === 0}
                isLastSection={lastSection}
                key={`${ section.id }_${ index }`}
                item={section}
                index={index}
              />
            )
          })}
        </div>
        <Footer {...site} footerTheme='green' />
      </Fragment>
    )
  }
}

Lookbook.propTypes = propTypes

export const lookbookQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulLookbook(filter: {id: {eq: $id}}) {
      edges {
        node {
          __typename
        id
        internalName
        displayTitle
        slug
        previewImage {
          ...Image
        }
        previewColor
        sections {
          ...WideMedia
          ...FiftyFifty
          ...ShoppableImage
          ...Columns
        }
        contentAlignment
        seo {
          description {
            description
          }
          keywords
          shareImage {
            file {
              url
            }
          }
        }
      }
    }
	}
}
`

export default Lookbook
